import File from '../../../assets/welcomedashboard/apps/file.png'
import { createPortal } from 'react-dom'
import { ImportFiles } from '../dialog/importFiles'
import React from 'react'
import PropTypes from 'prop-types'

export function UploadRecordings(props) {
  return (
    <div className="p-5 flex flex-col h-full overflow-y-hidden" style={{ maxHeight: '100%' }}>
      <h2 className="mb-1 text-[#40424A] text-[18px]">Upload Recordings</h2>
      <p className="my-4 text-[#62718D] font-light">
        Upload your user session recordings as Audio or Video files to be processed.
        <br /> <br />
        Upload one file at a time up to 8GB. Acceptable file types:{' '}
        <span className="font-semibold">MP3, MP4, MP2, AAC, WAV, FLAC, PCM, M4A, Ogg, Opus, WebM.</span>
      </p>
      <button
        onClick={props.onClick}
        className="bg-pink w-full text-white px-4 py-2 rounded-lg flex justify-center mt-auto"
      >
        <img src={File} className="w-[20px] me-3" alt="" /> Upload Recordings
      </button>

      {props.project &&
        props.showImportDialog &&
        createPortal(
          <ImportFiles
            projectId={props?.project?.id}
            projectName={props?.project?.name}
            handleClose={props.handleClose}
          />,
          document.body
        )}
    </div>
  )
}

UploadRecordings.propTypes = {
  onClick: PropTypes.func,
  project: PropTypes.any,
  showImportDialog: PropTypes.bool,
  handleClose: PropTypes.func,
}
