import React from 'react'
import NoMeetingsImage from '../../../assets/no_meetings_yet.png'
import { DynamicItems } from '../dynamic-items/DynamicItems'

export function NoMeetings() {
  return (
    <>
      <div className="py-10 pr-10">
        <div className="h-[475px] pl-[51px] pt-[32px] bg-[#FFF1C9] rounded-xl flex gap-[85px]">
          <div className="flex flex-col gap-[16px]">
            <h1 className="text-[34px] text-[#40424A] font-bold">No Meetings Yet!</h1>
            <div className="w-[364px]">
              <span className="text-[#676d7f] text-lg font-normal font-['DM_Sans'] leading-[23px]">The </span>
              <span className="text-[#676d7f] text-lg font-bold font-['DM_Sans'] leading-[23px]">Usermuse Bot</span>
              <span className="text-[#676d7f] text-lg font-normal font-['DM_Sans'] leading-[23px]">
                {' '}
                has not been invited to any user meetings yet. Simply invite it or upload a recording below.
              </span>
            </div>
          </div>

          <div className="mt-[12px] overflow-hidden">
            <img width={525} src={NoMeetingsImage} alt="" />
          </div>
        </div>
      </div>

      <DynamicItems vertical={false} padding={'pr-10'} />
    </>
  )
}
