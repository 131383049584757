import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

export function Textarea({ content, color, onChange, onKeyDown, readOnly }) {
  const textareaRef = useRef(null)

  useEffect(() => {
    const textArea = textareaRef.current
    if (textArea) {
      textArea.focus()
      textArea.setSelectionRange(textArea.value.length, textArea.value.length)
    }
  }, [])

  return (
    <textarea
      ref={textareaRef}
      readOnly={readOnly}
      style={{ resize: 'none', backgroundColor: color }}
      className={clsx(
        'focus:outline-none focus:ring-1 focus:ring-[#CF3F75] focus:shadow-xl',
        'w-[500px] h-[300px] py-[40px] px-[35px] justify-center items-center',
        "rounded-[9.46px] shadow text-[#40424A] font-semibold font-['Manrope']",
        'hideScrollbar',
        content.length < 120 ? 'text-[32px] leading-[48px] tracking-wider' : 'text-[24px] leading-[40px] tracking-wide'
      )}
      placeholder="Choose note content"
      value={content}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  )
}

Textarea.propTypes = {
  content: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
}
