import React, { createContext, useEffect, useState } from "react"
import { fetchAndActivate, getValue } from "firebase/remote-config"
import { remoteConfig } from "../firebase"
import PropTypes from 'prop-types'

export const RemoteConfigContext = createContext()

export const RemoteConfigProvider = ({ children }) => {
  const [config, setConfig] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await fetchAndActivate(remoteConfig)
        const googleDrive = getValue(remoteConfig, "google_drive").asBoolean()
        const oneDrive = getValue(remoteConfig, "one_drive").asBoolean()
        const zoom = getValue(remoteConfig, "zoom").asBoolean()
        const dropbox = getValue(remoteConfig, "dropbox").asBoolean()
        const box = getValue(remoteConfig, "box").asBoolean()
        setConfig({ googleDrive, oneDrive, zoom, dropbox, box })
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching Remote Config:", error)
      }
    }

    fetchConfig()
  }, [remoteConfig])

  return (
    <RemoteConfigContext.Provider value={{ config, isLoading }}>
      {children}
    </RemoteConfigContext.Provider>
  )
}

RemoteConfigProvider.propTypes = {
  children: PropTypes.node,
}
