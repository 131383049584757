/* eslint-disable react/no-unknown-property */
import React from 'react'
import Introduction from '../../assets/welcomedashboard/project/introduction.png'
import LeftText from '../../assets/welcomedashboard/project/notes/left-text1.png'
import RightText from '../../assets/welcomedashboard/project/notes/right-text1.png'
import LeftArrow from '../../assets/welcomedashboard/project/notes/left-arrow1.png'
import RightArrow from '../../assets/welcomedashboard/project/notes/right-arrow1.png'
import LeftText2 from '../../assets/welcomedashboard/project/notes/left-text2.png'
import RightText2 from '../../assets/welcomedashboard/project/notes/right-text2.png'
import LeftArrow2 from '../../assets/welcomedashboard/project/notes/left-arrow2.png'
import RightArrow2 from '../../assets/welcomedashboard/project/notes/right-arrow2.png'
import PropTypes from 'prop-types'
import { DynamicItems } from '../dashboard/dynamic-items/DynamicItems'

export function WelcomeProjectDashboard({ project = null }) {
  return (
    <>
      <div style={{ padding: '40px 2.5rem 40px 2.5rem' }}>
        <div className="bg-lightPink rounded-xl">
          <div className="pt-9 flex justify-center items-center">
            <div>
              <h1 className="text-3xl font-bold">Welcome to your new project!</h1>
              <p className="mt-2 text-zinc">Add user interviews to see project-level insights</p>
            </div>
          </div>

          <div className="grid grid-cols-3 justify-center items-center" style={{ gridTemplateColumns: '10% 43% 10%' }}>
            <div className="">
              <div>
                <img src={LeftText} alt="" />
                <div className="flex justify-end">
                  <img src={RightArrow} className="w-[70px]" alt="" />
                </div>
              </div>

              <div className="mt-5 sm:mt-10 md:mt-15 lg:mt-36 xl:mt-40">
                <img className="w-[290px]" src={LeftText2} alt="" />
                <div className="flex justify-end">
                  <img src={RightArrow2} className="w-[100px]" alt="" />
                </div>
              </div>
            </div>

            <div className="">
              <img src={Introduction} alt="" />
            </div>

            <div className="">
              <div>
                <img src={RightText} className="w-[190px]" alt="" />
                <div className="flex justify-start">
                  <img src={LeftArrow} className="w-[70px]" alt="" />
                </div>
              </div>

              <div className="mt-5 sm:mt-10 md:mt-15 lg:mt-36 xl:mt-40">
                <img src={RightText2} className="w-[160px]" alt="" />
                <div className="flex justify-start">
                  <img src={LeftArrow2} className="w-[70px]" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DynamicItems vertical={false} project={project} />
    </>
  )
}

WelcomeProjectDashboard.propTypes = {
  project: PropTypes.object,
}
