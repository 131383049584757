import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MeetingProvider } from '../utils/meetingProvider'
import { getMeetingProvider } from '../../utils'
import { useCustomFetch } from '../../hooks/useCustomFetch'
import cnt from '../../constants'
import { formatDate } from '../../utils/formatDate'
import toast from 'react-hot-toast'
import { CloseIcon } from '../icons/Close'
import { StarIcon } from '../icons/Star'
import { TrashIcon } from '../icons/Trash'
import { ProjectIcon } from '../icons/Project'
import { Dropdown } from './dropdown'
import { IconButton } from './iconButton'
import { Section } from './section'
import { Textarea } from './Textarea'
import { OriginalTranscriptCard } from '../project/OriginalTranscriptCard'
import MultiTag from '../tags/MultiTag'

function getColor(entity) {
  return (
    entity.color ||
    `${entity.sentiment_analysis === 'positive' ? '#BBE6B7' : entity.sentiment_analysis === 'negative' ? '#E5A8AC' : '#FFF98E'}`
  )
}

const Titles = {
  note: 'Need',
  feedback: 'Feedback',
  quote: 'Quote',
  problem: 'Problem',
}

export function EditDialog({
  entity,
  entityName,
  participants,
  setEntities,
  meeting,
  project,
  categories,
  setProject,
  close,
  readOnly = false,
  rating = true,
  tagging = true,
  changeContent,
  changeCategory,
  changeColor,
  deleteEntity,
}) {
  const [content, setContent] = useState('')
  const customFetch = useCustomFetch()
  console.log({ participants })

  async function handleToggleStar() {
    try {
      await customFetch(`/update${capitalizeFirst(entityName)}Star`, 'PUT', {
        [`${entityName}Id`]: entity.id,
        star: !entity.star,
      })
      setEntities((prevNotes) => prevNotes.map((n) => (n.id === entity.id ? { ...n, star: !n.star } : n)))
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  function handleCategoryChange(event) {
    changeCategory(event.target.value)
  }

  function handleColorChange(event) {
    changeColor(event.target.value)
  }

  function handleContentChange(event) {
    const newContent = event.target.value
    setContent(newContent)
    changeContent(newContent)
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') close()
  }

  function handlePropagation(event) {
    event.stopPropagation()
  }

  useEffect(() => {
    if (content !== entity.content) setContent(entity.content)
  }, [entity.content])

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black z-20" onClick={close}>
      <div onClick={handlePropagation} className="absolute top-[6%] left-[10%] w-[84%] h-[86%] bg-white rounded-2xl">
        <div className="h-full flex">
          <div
            className="flex grow flex-col justify-between h-full p-8 bg-grey-100 rounded-2xl overflow-y-hidden"
            style={{ width: 'calc(100% - 352px)' }}
          >
            <p className="text-medSlate text-4xl font-normal font-['DM_Sans'] leading-[41.40px] mb-5">
              {Titles[entityName]}
            </p>
            <div className="flex flex-col items-center justify-center mb-10">
              <Textarea
                content={content}
                readOnly={readOnly}
                color={getColor(entity)}
                onChange={handleContentChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <OriginalTranscriptCard note={entity} meeting={meeting} participants={participants} />
          </div>

          <div className="w-[352px] pl-[24px] py-[24px] pr-[39px] border-l-2 border-[#D3D3D6] overflow-auto hideScrollbar">
            <Section title={`${Titles[entityName]} DETAILS`}>
              <button className="absolute top-3 right-1" onClick={close}>
                <CloseIcon />
              </button>
            </Section>

            {(rating || deleteEntity) && (
              <Section title="">
                {rating && <IconButton onClick={handleToggleStar} icon={StarIcon} checked={entity.star} />}
                {deleteEntity && <IconButton onClick={deleteEntity} icon={TrashIcon} />}
              </Section>
            )}

            {changeColor && (
              <Section title="Note Color">
                <Dropdown
                  value={entity.color}
                  onChange={handleColorChange}
                  options={Object.keys(cnt.colors).map((color) => ({ value: cnt.colors[color], label: color }))}
                  icon={
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="10" cy="10" r="9" fill={entity.color} stroke="#505053" strokeWidth="2" />
                    </svg>
                  }
                />
              </Section>
            )}

            {entity.created_by && (
              <Section title="Created By">
                <p className="text-medSlate text-lg font-bold font-['Manrope'] leading-tight tracking-wide">
                  {entity.created_by}
                </p>
              </Section>
            )}

            <Section title="Created">
              <p className="text-[#62718D] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">
                {formatDate(entity.created_at)}
              </p>
            </Section>

            {project && (
              <Section title="Project">
                <div className="flex items-center gap-[8px]">
                  <ProjectIcon />
                  <p className="text-[#40424A] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">
                    {project.name}
                  </p>
                </div>
              </Section>
            )}
            {!!changeCategory && (
              <Section title="Category">
                <Dropdown
                  value={entity.category_id}
                  onChange={handleCategoryChange}
                  options={categories.map((cat) => ({ value: cat.id, label: cat.content }))}
                />
              </Section>
            )}

            {tagging && (
              <Section title="Tags">
                <MultiTag
                  entity={entity}
                  setEntities={setEntities}
                  project={project}
                  setProject={setProject}
                  entityName={entityName}
                />
              </Section>
            )}

            {!!Object.keys(meeting).length && (
              <Section title="Session">
                <div className="flex items-center gap-[8px]">
                  <MeetingProvider provider={!meeting.thread_id ? 'custom' : getMeetingProvider(meeting.meeting_url)} />
                  <p className="text-[#40424A] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">
                    {meeting.name}
                  </p>
                </div>
              </Section>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function capitalizeFirst(str) {
  return str[0].toUpperCase() + str.substring(1).toLowerCase()
}

EditDialog.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    color: PropTypes.string,
    sentiment_analysis: PropTypes.oneOf(['positive', 'negative', 'neutral']),
    star: PropTypes.bool,
    created_by: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    category_id: PropTypes.string,
  }).isRequired,
  entityName: PropTypes.oneOf(['note', 'feedback', 'quote', 'problem']).isRequired,
  setEntities: PropTypes.func.isRequired,
  meeting: PropTypes.shape({
    name: PropTypes.string,
    meeting_url: PropTypes.string,
    thread_id: PropTypes.string,
  }).isRequired,
  project: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
  setProject: PropTypes.func,
  changeContent: PropTypes.func,
  changeCategory: PropTypes.func,
  changeColor: PropTypes.func,
  readOnly: PropTypes.bool,
  rating: PropTypes.bool,
  tagging: PropTypes.bool,
  deleteEntity: PropTypes.func,
}
