import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { useCustomFetch } from '../../hooks/useCustomFetch'
import useOutsideClick from '../../hooks/useOutsideClick'

const Routes = {
  feedback: {
    post: '/addFeedbackTag',
    delete: '/deleteFeedbackTag',
  },
  note: {
    post: '/addNoteTag',
    delete: '/deleteNoteTag',
  },
}

function getSuggestions(project, note, input) {
  return project.tags
    ? Object.keys(project.tags).filter(
        (suggestion) => !note.tags.includes(suggestion) && suggestion.toLowerCase().includes(input.toLowerCase())
      )
    : []
}

const MultiTag = ({ entity, setEntities, project, setProject, entityName }) => {
  const [input, setInput] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const suggestions = getSuggestions(project, entity, input)
  const formRef = useRef()
  const inputRef = useRef()
  const customFetch = useCustomFetch()

  useOutsideClick(formRef, () => {
    if (showSuggestions) {
      setShowSuggestions(false)
    }
  })

  const handleAddTag = async (e) => {
    e.preventDefault()
    if (input.trim() && !entity.tags.includes(input.trim())) {
      try {
        setEntities((prevFeedbacks) =>
          prevFeedbacks.map((f) => (f.id === entity.id ? { ...f, tags: [...f.tags, input.trim()] } : f))
        )
        setInput('')
        const res = await customFetch(Routes[entityName].post, 'POST', {
          [`${entityName}Id`]: entity.id,
          tag: input.trim(),
        })
        setProject((prevProject) => ({ ...prevProject, tags: res.projectTags }))
      } catch (error) {
        console.log(error)
        toast.error('Whoops! Something went wrong. Please try again.')
      }
    }
  }

  const handleAddSuggestion = async (suggestion) => {
    try {
      setEntities((prevFeedbacks) =>
        prevFeedbacks.map((f) => (f.id === entity.id ? { ...f, tags: [...f.tags, suggestion] } : f))
      )
      setInput('')
      const res = await customFetch(Routes[entityName].post, 'POST', {
        [`${entityName}Id`]: entity.id,
        tag: suggestion,
      })
      setProject((prevProject) => ({ ...prevProject, tags: res.projectTags }))
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  const handleRemoveTag = async (tagToRemove) => {
    try {
      setEntities((prevFeedbacks) =>
        prevFeedbacks.map((f) => (f.id === entity.id ? { ...f, tags: f.tags.filter((tag) => tag !== tagToRemove) } : f))
      )
      setInput('')
      const res = await customFetch(Routes[entityName].delete, 'DELETE', {
        [`${entityName}Id`]: entity.id,
        tag: tagToRemove,
      })
      setProject((prevProject) => ({ ...prevProject, tags: res.projectTags }))
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  return (
    <div className="flex flex-wrap gap-2">
      {entity.tags &&
        entity.tags.map((tag) => (
          <button
            key={tag}
            className="flex items-center text-medSlate rounded-full px-3 py-1 shadow gap-2"
            onClick={() => handleRemoveTag(tag)}
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2701_7575)">
                <path
                  d="M5.28711 6.06423C5.28711 6.24962 5.36075 6.42741 5.49184 6.5585C5.62293 6.68959 5.80072 6.76323 5.98611 6.76323C6.17149 6.76323 6.34929 6.68959 6.48037 6.5585C6.61146 6.42741 6.68511 6.24962 6.68511 6.06423C6.68511 5.87885 6.61146 5.70105 6.48037 5.56997C6.34929 5.43888 6.17149 5.36523 5.98611 5.36523C5.80072 5.36523 5.62293 5.43888 5.49184 5.56997C5.36075 5.70105 5.28711 5.87885 5.28711 6.06423Z"
                  stroke="#FF4A8E"
                  strokeWidth="1.6776"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.83984 5.01496V8.63018C2.83992 9.00092 2.98726 9.35645 3.24946 9.61857L8.63873 15.0078C8.95465 15.3237 9.38309 15.5012 9.82983 15.5012C10.2766 15.5012 10.705 15.3237 11.0209 15.0078L14.9297 11.099C15.2456 10.7831 15.423 10.3547 15.423 9.90795C15.423 9.46121 15.2456 9.03277 14.9297 8.71686L9.54044 3.32758C9.27833 3.06539 8.9228 2.91805 8.55206 2.91797H4.93684C4.38068 2.91797 3.8473 3.1389 3.45404 3.53216C3.06078 3.92543 2.83984 4.45881 2.83984 5.01496Z"
                  stroke="#FF4A8E"
                  strokeWidth="1.6776"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2701_7575">
                  <rect width="16.776" height="16.776" fill="white" transform="translate(0.743164 0.822266)" />
                </clipPath>
              </defs>
            </svg>
            <p className="text-slate-600 text-[15.10px] font-bold font-['Manrope'] leading-none tracking-wide">{tag}</p>
          </button>
        ))}
      <form onSubmit={handleAddTag} className="flex items-center relative" ref={formRef}>
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="p-1"
          placeholder="Add a tag"
          onFocus={() => setShowSuggestions(true)}
        />
        <button type="submit" className="hidden">
          Add
        </button>
        {showSuggestions && !!suggestions.length && (
          <div className="absolute top-full mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
            {suggestions.map((suggestion) => (
              <div
                key={suggestion}
                onClick={() => handleAddSuggestion(suggestion)}
                className="px-3 py-1 hover:bg-blue-200 cursor-pointer"
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </form>
    </div>
  )
}

MultiTag.propTypes = {
  entity: PropTypes.object,
}

export default MultiTag
