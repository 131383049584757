import React from 'react'
import PropTypes from 'prop-types'
import { CreateProject } from './createProject'
import { ChooseExistingProject } from './chooseExistingProject'
import { ImportFiles } from './importFiles'
import useProjectSelection from '../../../hooks/useProjectSelection'

export function ProjectLayout({ link, importFile, handleClose }) {
  const {
    isNewProject,
    setIsNewProject,
    showImportDialog,
    projectId,
    projectName,
    handleCreateProject,
    handleChooseExistingProject,
  } = useProjectSelection(link, importFile)

  if (showImportDialog) {
    return <ImportFiles projectId={projectId} projectName={projectName} handleClose={handleClose} />
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black z-20" onClick={() => handleClose(true)}>
      <div
        className="absolute left-1/4 right-1/4 bg-white p-[28px] rounded-xl"
        style={{ top: '10%' }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mb-[29px] flex items-center justify-between">
          <p className="text-zinc text-[26px] font-semibold font-['Manrope']">
            {isNewProject ? 'Add New Project' : 'Add to Existing Project'}
          </p>
          <div className="flex items-center">
            {(link || importFile) && (
              <button
                className="mr-[18px] px-4 py-2.5 rounded-[100px] shadow-inner border border-pink justify-center items-center gap-3 inline-flex"
                onClick={() => setIsNewProject(!isNewProject)}
              >
                <p className="text-pink text-base font-semibold font-['Manrope'] leading-tight">
                  {isNewProject ? 'Add to Existing Project' : 'Add to New Project'}
                </p>
              </button>
            )}
            <button onClick={() => handleClose(true)}>
              <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M26.8577 12L13.7158 24"
                  stroke="#40424A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.7158 12L26.8577 24"
                  stroke="#40424A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        {isNewProject ? (
          <CreateProject handleCreateProject={handleCreateProject} handleClose={handleClose} />
        ) : (
          <ChooseExistingProject handleChooseExistingProject={handleChooseExistingProject} />
        )}
      </div>
    </div>
  )
}

ProjectLayout.propTypes = {
  link: PropTypes.string,
  importFile: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
}
