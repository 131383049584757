import {HelpIcon} from '../../icons/Help'
import Calendar from '../../../assets/welcomedashboard/apps/calendar.png'
import Microsoft from '../../../assets/welcomedashboard/apps/microsoft.png'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

export function ScheduleInterview(props) {
  return (
      <div className="p-5 flex flex-col h-full overflow-y-hidden" style={{maxHeight: '100%'}}>
        <h2 className="mb-1 text-[#40424A] text-[18px]">Schedule Interview</h2>
        <p className="mt-4 text-[#62718D] font-light">
          Add any meeting scheduled to this project by inviting Usermuse using this unique email address.
        </p>
        <div className="flex flex-wrap items-center mx-2 my-5">
          <p className="mr-1 text-xs font-semibold font-['Manrope']">INVITE EMAIL</p>
          <HelpIcon
              className="mt-2"
              maxWidth={300}
              renderProps={(icon) => <div className="ml-1 mr-6">{icon}</div>}
              content={'Use this unique email to invite Usermuse to additional meetings for this project.'}
          />
          <button className="h-[27px] bg-white rounded-[50px] flex items-center p-4 mt-2 w-full"
                  onClick={props.onClick}>
            <p className="text-zinc text-[13px] font-medium font-['Manrope'] mr-2">
              {props.project ? `invite+${props.project.stem || props.project.id}@usermuse.co` : 'invite@usermuse.co'}
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 15 15" fill="none">
              <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                  fill="#FF4A8E"
              />
            </svg>
          </button>
        </div>
        <button
            className={clsx('bg-pink w-full text-white px-4 py-2 rounded-lg flex justify-center', props.isVertical ? 'mt-1' : 'mt-auto')}
            onClick={props.onClick1}
        >
          {!props.user.integrations?.googleCalendar && !props.user.integrations?.microsoftCalendar ? (
              'Connect Calendar'
          ) : (
              <>
                <img
                    src={props.user.integrations?.googleCalendar ? Calendar : Microsoft}
                    className="w-[20px] me-3"
                    alt="Calendar"
                />
                {` Schedule on ${props.user.integrations?.googleCalendar ? 'Google Calendar' : 'Microsoft Calendar'}`}
              </>
          )}
        </button>
      </div>
  )
}

ScheduleInterview.propTypes = {
  onClick: PropTypes.func,
  project: PropTypes.any,
  isVertical: PropTypes.bool,
  onClick1: PropTypes.func,
  user: PropTypes.any,
}
