import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { AuthContext } from '../../../context/AuthContext'
import { DashboardContext } from '../../../context/DashboardContext'
import { ScheduleInterview } from './ScheduleInterview'
import { JoinLiveMeeting } from './JoinLiveMeeting'
import { UploadRecordings } from './UploadRecordings'
import Meetings from '../../../assets/welcomedashboard/apps/meetings.png'
import Calendars from '../../../assets/welcomedashboard/apps/calendars.png'
import Upload from '../../../assets/welcomedashboard/apps/upload.png'
import '../../project/project.css'
import { useCustomFetch } from '../../../hooks/useCustomFetch'
import useProjectSelection from '../../../hooks/useProjectSelection'

/**
 * A React component that renders dynamic items with configurable layout and actions.
 * This component provides functionality for meeting links, calendar scheduling, and file uploading,
 * adapting its presentation for small screens and user requirements.
 *
 * @param {Object} props - The component props.
 * @param {boolean} [props.vertical=false] - Determines the orientation of the items. If true, items are rendered in a vertical layout.
 * @param {Object} [props.project=null] - The project object used to configure certain actions like generating calendar links or project-related actions.
 * @param {string} [props.padding='px-10'] - The padding CSS class name to be applied to the container.
 * @return {JSX.Element} Returns the rendered component with dynamic items based on the provided props.
 */
export function DynamicItems({ vertical = false, project = null, padding = 'px-10' }) {
  const { user } = useContext(AuthContext)
  const { shouldFireZoomToast } = useContext(DashboardContext)
  const [showSelectProjectDialog, setShowSelectProjectDialog] = useState(false)
  const [importFile, setImportFile] = useState(false)
  const [link, setLink] = useState('')
  const [showImportFileDialog, setShowImportFileDialog] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const navigate = useNavigate()
  const customFetch = useCustomFetch()
  const { handleChooseExistingProject } = useProjectSelection(link, importFile)

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches
      const isTablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)').matches
      const isSmallLaptop = window.matchMedia('(min-width: 1025px) and (max-width: 1366px)').matches

      setIsSmallScreen(isMobile || isTablet || isSmallLaptop)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  async function copyToClipboard() {
    try {
      project
        ? await navigator.clipboard.writeText(`invite+${project.stem || project.id}@usermuse.co`)
        : await navigator.clipboard.writeText(`invite@usermuse.co`)
      toast.success('Copied!')
    } catch {
      toast.error('Failed to copy!')
    }
  }

  const handleDialog = () => {
    if (project) {
      setShowImportFileDialog(true)
    } else {
      setImportFile(true)
      setShowSelectProjectDialog(true)
    }
  }

  const generateCalendarLink = async () => {
    if (user.integrations?.googleCalendar) {
      const baseUrl = 'https://calendar.google.com/calendar/render?action=TEMPLATE'
      const title = encodeURIComponent('New User Interview')
      const guests = encodeURIComponent(
        project !== null ? `invite+${project.stem || project.id}@usermuse.co` : 'invite@usermuse.co'
      )
      const eventLink = `${baseUrl}&text=${title}&add=${guests}`
      window.open(eventLink, '_blank')
    } else if (user.integrations?.microsoftCalendar) {
      const { isBusinessAccount, error } = await customFetch('/auth/isBusinessAccount')
      if (error) return toast.error('Failed to generate calendar link')
      const baseUrl = `https://outlook.${isBusinessAccount ? 'office' : 'live'}.com/calendar/0/deeplink/compose`
      const title = encodeURIComponent('New User Interview')
      const guests = encodeURIComponent(
        project !== null ? `invite+${project.stem || project.id}@usermuse.co` : 'invite@usermuse.co'
      )
      const eventLink = `${baseUrl}?subject=${title}&to=${guests}`
      window.open(eventLink, '_blank')
    } else {
      navigate('/dashboard/integrations')
    }
  }

  function handleLink() {
    if (link) {
      try {
        new URL(link)
        const fireToast = shouldFireZoomToast(link)
        if (!fireToast && !project) setShowSelectProjectDialog(true)
        if (!fireToast && project) handleChooseExistingProject(project)
      } catch {
        alert('Please enter a valid link')
      }
    } else {
      alert('Please enter a link')
    }
  }

  return (
    <div className={clsx('flex justify-center', padding, vertical ? 'flex-col pt-10' : 'flex-row gap-10 flex-wrap')}>
      <DynamicItemPanel
        vertical={vertical || isSmallScreen}
        imageSrc={Meetings}
        altText="meetings"
        backgroundColor="#f8ffc8"
        headerBackgroundColor="#E2E8B7"
        height="320px"
        imageSize={{ vertical: 'w-[150px]', horizontal: 'w-[140px]' }}
      >
        <JoinLiveMeeting
          onChange={(e) => setLink(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleLink()
          }}
          showDialog={showSelectProjectDialog}
          link={link}
          importFile={importFile}
          handleClose={() => {
            setImportFile(false)
            setShowSelectProjectDialog(false)
          }}
          onClick={() => handleLink()}
        />
      </DynamicItemPanel>

      <DynamicItemPanel
        vertical={vertical || isSmallScreen}
        imageSrc={Calendars}
        altText="calendars"
        backgroundColor="#fee4d9"
        headerBackgroundColor="#FED7C8"
        height="364px"
        imageSize={{ vertical: 'w-[180px]', horizontal: 'w-[185px]' }}
      >
        <ScheduleInterview onClick={copyToClipboard} project={project} onClick1={generateCalendarLink} user={user} vertical={vertical || isSmallScreen} />
      </DynamicItemPanel>

      <DynamicItemPanel
        vertical={vertical || isSmallScreen}
        imageSrc={Upload}
        altText="upload"
        backgroundColor="#cdf2ff"
        headerBackgroundColor="#ABE6FC"
        imageSize={{ vertical: 'w-[65px]', horizontal: 'w-[60px]' }}
      >
        <UploadRecordings
          onClick={() => handleDialog()}
          project={project}
          showImportDialog={showImportFileDialog}
          handleClose={() => {
            setShowImportFileDialog(false)
          }}
        />
      </DynamicItemPanel>
    </div>
  )
}

const DEFAULT_PANEL_HEIGHT = '360px'

/**
 * Renders a dynamic item panel with an image, customizable background, and children elements.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.vertical - Determines the layout orientation of the panel. If true, the panel uses a vertical layout.
 * @param {string} props.imageSrc - The source URL for the image to be displayed in the panel.
 * @param {string} props.altText - The alt text for the image, used for accessibility.
 * @param {string} props.backgroundColor - The background color for the panel.
 * @param {React.ReactNode} props.children - The child components to be rendered inside the panel.
 * @param {Object} props.imageSize - The image size configuration for vertical and horizontal layouts.
 * @param {string} props.imageSize.vertical - The CSS classes to apply for the image size in a vertical layout.
 * @param {string} props.imageSize.horizontal - The CSS classes to apply for the image size in a horizontal layout.
 *
 * @return {JSX.Element} The rendered DynamicItemPanel component.
 */
function DynamicItemPanel({
  vertical,
  imageSrc,
  altText,
  height = DEFAULT_PANEL_HEIGHT,
  backgroundColor,
  children,
  imageSize,
  headerBackgroundColor,
}) {
  const finalHeight = vertical ? height : DEFAULT_PANEL_HEIGHT
  return (
    <div className={clsx('h-full flex-1', vertical ? 'pb-10 pr-10' : '')}>
      <div
        className="rounded-xl h-full shadow-lg flex flex-col"
        style={{
          backgroundColor,
          minWidth: '340px',
          width: '100%',
          maxHeight: finalHeight,
          height: finalHeight,
          minHeight: finalHeight,
        }}
      >
        <div
          className={clsx('rounded-t-xl flex justify-center items-center overflow-hidden min-h-16')}
          style={{ backgroundColor: headerBackgroundColor || backgroundColor }}
        >
          <img
            src={imageSrc}
            alt={altText}
            className={clsx(vertical ? imageSize.vertical : imageSize.horizontal)}
            style={{ transform: vertical ? 'translateY(12px)' : null }}
          />
        </div>
        {children}
      </div>
    </div>
  )
}

DynamicItemPanel.propTypes = {
  vertical: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node,
  imageSize: PropTypes.shape({
    vertical: PropTypes.string.isRequired,
    horizontal: PropTypes.string.isRequired,
  }).isRequired,
  headerBackgroundColor: PropTypes.string,
}

DynamicItems.propTypes = {
  vertical: PropTypes.bool,
  project: PropTypes.shape({
    id: PropTypes.string,
    stem: PropTypes.string,
  }),
  padding: PropTypes.string,
}
