import React, { useContext } from 'react'
import defaultAvatar from '../../../assets/default_avatar.png'
import { MeetingsContext } from '../../../context/MeetingContext'
import { Transcript } from './transcript'
import { Participant } from './participant'

export function Sidebar () {
  const { meetingOverview, participants, transcripts } = useContext(MeetingsContext)
  const intervieweeName = participants[Object.keys(participants).find(participant => participants[participant].role === 'interviewee')]?.name

  return (
    <div className="w-[485px] h-full pb-[80px] overflow-auto hideScrollbar">
      <div className="mt-[30px] bg-white px-[28px] pt-[28px] pb-[35px] rounded-[5px] border border-[#e4e4e4] flex flex-col gap-[12px]">
        <div className='flex items-center justify-between pb-[10px] border-b border-b-[#e4e4e4]'>
          <p className="text-[#3f424a] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{intervieweeName}</p>
          <img className='h-8 w-8 mx-1 rounded-full' src={defaultAvatar} alt="user avatar" />
        </div>

        <div className='flex flex-wrap items-center pb-[10px] border-b border-b-[#e4e4e4] gap-x-[8px] gap-y-[10px]'>
          <div className='flex items-center gap-[6px] bg-[#f9f9f9] rounded-[30px] px-[10px] py-[4px]'>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.75 5.25065C1.75 4.94123 1.87292 4.64449 2.09171 4.42569C2.3105 4.2069 2.60725 4.08398 2.91667 4.08398H11.0833C11.3928 4.08398 11.6895 4.2069 11.9083 4.42569C12.1271 4.64449 12.25 4.94123 12.25 5.25065V10.5007C12.25 10.8101 12.1271 11.1068 11.9083 11.3256C11.6895 11.5444 11.3928 11.6673 11.0833 11.6673H2.91667C2.60725 11.6673 2.3105 11.5444 2.09171 11.3256C1.87292 11.1068 1.75 10.8101 1.75 10.5007V5.25065Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.66602 4.08333V2.91667C4.66602 2.60725 4.78893 2.3105 5.00772 2.09171C5.22652 1.87292 5.52326 1.75 5.83268 1.75H8.16602C8.47543 1.75 8.77218 1.87292 8.99097 2.09171C9.20977 2.3105 9.33268 2.60725 9.33268 2.91667V4.08333" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">{meetingOverview.background?.job || 'Not Specified'}</p>
          </div>

          <div className='flex items-center gap-[6px] bg-[#f9f9f9] rounded-[30px] px-[10px] py-[4px]'>
            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.33252 3.66649C2.33252 4.37368 2.61345 5.05192 3.11352 5.55198C3.61358 6.05204 4.29181 6.33297 4.99901 6.33297C5.7062 6.33297 6.38443 6.05204 6.8845 5.55198C7.38456 5.05192 7.66549 4.37368 7.66549 3.66649C7.66549 2.95929 7.38456 2.28106 6.8845 1.781C6.38443 1.28093 5.7062 1 4.99901 1C4.29181 1 3.61358 1.28093 3.11352 1.781C2.61345 2.28106 2.33252 2.95929 2.33252 3.66649Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M1 12.9997V11.6665C1 10.9593 1.28093 10.2811 1.781 9.781C2.28106 9.28093 2.95929 9 3.66649 9H6.33297C7.04017 9 7.7184 9.28093 8.21847 9.781C8.71853 10.2811 8.99946 10.9593 8.99946 11.6665V12.9997" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">{meetingOverview.background?.demo || 'Not Specified'}</p>
          </div>

          <div className='flex items-center gap-[6px] bg-[#f9f9f9] rounded-[30px] px-[10px] py-[4px]'>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.25 6.41602C5.25 6.88014 5.43437 7.32526 5.76256 7.65345C6.09075 7.98164 6.53587 8.16602 7 8.16602C7.46413 8.16602 7.90925 7.98164 8.23744 7.65345C8.56563 7.32526 8.75 6.88014 8.75 6.41602C8.75 5.95189 8.56563 5.50677 8.23744 5.17858C7.90925 4.85039 7.46413 4.66602 7 4.66602C6.53587 4.66602 6.09075 4.85039 5.76256 5.17858C5.43437 5.50677 5.25 5.95189 5.25 6.41602Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10.3006 9.71641L7.82549 12.1915C7.60673 12.41 7.31016 12.5328 7.00094 12.5328C6.69173 12.5328 6.39516 12.41 6.1764 12.1915L3.70074 9.71641C3.04812 9.06376 2.60368 8.23224 2.42364 7.327C2.24359 6.42176 2.33602 5.48346 2.68924 4.63076C3.04245 3.77805 3.64059 3.04923 4.40802 2.53646C5.17544 2.02369 6.07768 1.75 7.00065 1.75C7.92362 1.75 8.82586 2.02369 9.59329 2.53646C10.3607 3.04923 10.9589 3.77805 11.3121 4.63076C11.6653 5.48346 11.7577 6.42176 11.5777 7.327C11.3976 8.23224 10.9532 9.06376 10.3006 9.71641Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className="text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">{meetingOverview.background?.location || 'Not Specified'}</p>
          </div>
        </div>

        <div className='pb-[10px] border-b border-b-[#e4e4e4] flex flex-col gap-[20px]'>
          <div className='flex gap-[6px]'>
            <div className='w-[18px] h-[18px]'>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4585_19266)">
                <path d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9Z" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 6.75H9.01" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.25 9H9V12H9.75" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_4585_19266">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <p className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">{meetingOverview.background?.bullet_1}</p>
          </div>

          <div className='flex gap-[6px]'>
            <div className='w-[18px] h-[18px]'>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4585_19266)">
                <path d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9Z" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 6.75H9.01" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.25 9H9V12H9.75" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_4585_19266">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <p className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">{meetingOverview.background?.bullet_2}</p>
          </div>

          <div className='flex gap-[6px]'>
            <div className='w-[18px] h-[18px]'>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4585_19266)">
                <path d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9Z" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 6.75H9.01" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.25 9H9V12H9.75" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_4585_19266">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <p className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">{meetingOverview.background?.bullet_3}</p>
          </div>
        </div>

        {['yes', 'no', 'maybe'].includes(meetingOverview?.validation?.is_prospect_to_use_it) && (
        <div className='flex gap-[6px]'>
          {meetingOverview?.validation?.is_prospect_to_use_it === 'yes'
            ? (
            <>
            <div className='w-[18px] h-[18px] mt-1'>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4108_1380)">
                <path d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z" stroke="#259A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.75 9L8.25 10.5L11.25 7.5" stroke="#259A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_4108_1380">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <p><span className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-[23px]">{intervieweeName} is a Likely User. </span><span className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">{meetingOverview?.validation?.reason}</span></p>
            </>
              )
            : meetingOverview?.validation?.is_prospect_to_use_it === 'no'
              ? (
              <>
              <div className='w-[18px] h-[18px] mt-1'>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_4736_23694)">
                  <path d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z" stroke="#D40814" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7.5 7.5L10.5 10.5M10.5 7.5L7.5 10.5" stroke="#D40814" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_4736_23694">
                  <rect width="18" height="18" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              <p><span className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-[23px]">{intervieweeName} is an Unlikely User. </span><span className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">{meetingOverview?.validation?.reason}</span></p>
              </>
                )
              : (
            <>
            <div className='w-[18px] h-[18px] mt-1'>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4736_23678)">
                <path d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z" stroke="#DCAE1D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 12V12.0075" stroke="#DCAE1D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 9.74982C9.33731 9.75084 9.66512 9.63813 9.93049 9.4299C10.1959 9.22166 10.3833 8.93007 10.4625 8.60219C10.5418 8.27432 10.5082 7.9293 10.3672 7.62287C10.2262 7.31643 9.98605 7.06646 9.6855 6.91332C9.38713 6.76048 9.04583 6.71309 8.71711 6.77886C8.38839 6.84463 8.09159 7.0197 7.875 7.27557" stroke="#DCAE1D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_4736_23678">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <p><span className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-[23px]">{intervieweeName} is a Maybe User. </span><span className="text-[#3f424a] text-base font-normal font-['Manrope'] leading-[23px]">{meetingOverview?.validation?.reason}</span></p>
            </>
                )}
        </div>
        )}
      </div>

      <div className='mt-[30px] pb-[12px] border-b border-b-[#e4e4e4]'>
        <p className="text-[#3f424a] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">Transcript</p>
      </div>

      <div className='mt-[17px] pb-[17px] border-b border-b-[#e4e4e4]'>
        <p className="mb-[11px] text-[#62708d] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Participants</p>
        <div className='flex items-center gap-[7px] flex-wrap'>
          {Object.keys(participants).map(participantId => <Participant key={participantId} participantId={participantId} />)}
        </div>
      </div>

      {transcripts.map((transcript, index) => <Transcript key={index} transcript={transcript} />)}

    </div>
  )
}
