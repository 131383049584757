import { VideoCameraIcon } from '../../icons/VideoCamera'
import { createPortal } from 'react-dom'
import { ProjectLayout } from '../dialog/projectLayout'
import React from 'react'
import PropTypes from 'prop-types'

export function JoinLiveMeeting(props) {
  return (
    <div className="p-5 flex flex-col h-full overflow-y-hidden" style={{ maxHeight: '100%' }}>
      <h2 className="mb-1 text-[#40424A] text-[18px]">Join Live Meeting</h2>
      <p className="my-3 text-[#62718D] font-light">
        To start transcribing and analyzing a live meeting, just paste the meeting link here:
      </p>
      <div className="flex w-full h-9 my-3">
        <div className="relative grow">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <VideoCameraIcon />
          </div>
          <input
            type="url"
            id="default-search"
            className="h-full w-full ps-10 py-2 text-sm placeholder-grey-400 text-grey-400 bg-grey-100 rounded border border-grey-200"
            placeholder="Paste meeting link"
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            required
          />
        </div>
      </div>
      {props.showDialog &&
        createPortal(
          <ProjectLayout link={props.link} importFile={props.importFile} handleClose={props.handleClose} />,
          document.body
        )}
      <button onClick={props.onClick} className="bg-pink w-full text-white px-4 py-2 rounded-lg mt-auto">
        Join Meeting
      </button>
    </div>
  )
}

JoinLiveMeeting.propTypes = {
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  showDialog: PropTypes.bool,
  link: PropTypes.string,
  importFile: PropTypes.bool,
  handleClose: PropTypes.func,
  onClick: PropTypes.func,
}
