import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { AuthContext } from './AuthContext'
import { useCustomFetch } from '../hooks/useCustomFetch'
import { useNavigate } from 'react-router-dom'
import { getPublicStorageUrl } from '../utils'

export const DashboardContext = createContext()

export const DashboardContextProvider = ({ children }) => {
  const [refreshContext, setRefreshContext] = useState(false)
  const [allProjects, setAllProjects] = useState([])
  const [meetings, setMeetings] = useState([])
  const [allSessions, setAllSessions] = useState([]) // sessions are meetings with files
  const [sessions, setSessions] = useState([])
  const [sessionsLoading, setSessionsLoading] = useState(true)
  const [meetingsOverview, setMeetingsOverview] = useState({}) // { meetingId: { background: '', validation: {...}, ... } }
  const [filteredProjects, setFilteredProjects] = useState([])
  const [projectsLoading, setProjectsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [workspacesLoading, setWorkspacesLoading] = useState(true)
  const [workspaces, setWorkspaces] = useState({}) // { workspaceId: doc.data() }
  const [activeWorkspace, setActiveWorkspace] = useState({}) // { id: workspaceId, name: workspaceName, sub_id: '',  ... }
  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(true)
  const { user } = useContext(AuthContext)
  const zoomToastIdRef = useRef(null) // Ref to keep track of the zoom toast ID
  const navigate = useNavigate()
  const customFetch = useCustomFetch()

  const showOrUpdateZoomToast = () => {
    if (zoomToastIdRef.current) {
      toast.dismiss(zoomToastIdRef.current) // Dismiss previous toast if it exists
    }
    zoomToastIdRef.current = toast.error(() => (
      <div className='flex gap-[10px]'>
        <p>Bot cannot join Zoom because you haven’t connected your Zoom account.</p>
        <button className="border border-[#F9F9F9] rounded px-1" style={{ color: '#0052CC' }} onClick={() => {
          navigate('/dashboard/integrations')
          clearZoomToast()
        }}>Connect</button>
        <button className="border border-[#F9F9F9] rounded px-1" style={{ color: '#0052CC' }} onClick={clearZoomToast}>Dismiss</button>
      </div>
    ), { duration: Infinity, style: {
      minWidth: '750px',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginTop: '10px',
    }})
  }

  const clearZoomToast = () => {
    if (zoomToastIdRef.current) {
      toast.dismiss(zoomToastIdRef.current)
      zoomToastIdRef.current = null
    }
  }

  const isZoomUrl = (meetingUrl) => {
    const zoomURLRegex = /^(https?:\/\/)?(zoom\.us|us\d+web\.zoom\.us)\/j\/\d{9,11}(\/?|\?\S+)$/
    return zoomURLRegex.test(meetingUrl)
  }

  const shouldFireZoomToast = (link) => {
    if (!user.integrations.zoom && isZoomUrl(link)) {
      showOrUpdateZoomToast()
      return true
    }
    return false
  }

  function filterProjects(term) {
    setFilteredProjects(allProjects.filter((project) => project.name.toLowerCase().includes(term.toLowerCase())))
  }

  const getCalendarEvents = async () => {
    setEventsLoading(true)
    // const url = `/getCalendarEvents${(limit) ? '?limit=' + limit : ''}`
    const url = '/getCalendarEvents'
    const data = await customFetch(url, 'GET', null)
    if (data.error) return
    // const filteredEvents = data.events.filter((event) => event.workspaceId === user.active_workspace)
    const filteredEvents = data.events
    setEvents(filteredEvents)
    setEventsLoading(false)
    if (!user.integrations.zoom && filteredEvents.filter(ev => ev.isOrganizer && isZoomUrl(ev.meetingUrl)).length) showOrUpdateZoomToast()
  }

  function cleanMeetingState() {
    setMeetings([])
    setMeetingsOverview([])
    setAllSessions([])
    setSessions([])
    setSessionsLoading(false)
  }

  function cleanProjectState() {
    setAllProjects([])
    setFilteredProjects([])
    cleanMeetingState()
  }

  async function setProjects() {
    setProjectsLoading(true)
    if (user.active_workspace) {
      const q = query(collection(db, 'projects'), where('workspace_id', '==', user.active_workspace))
      const docRefs = await getDocs(q)

      if (!docRefs.empty) {
        const projectRes = []

        docRefs.forEach((project) => {
          projectRes.push({
            id: project.id,
            ...project.data(),
          })
        })

        projectRes.sort((a, b) => (a.created_at - b.created_at > 0 ? -1 : 1))
        setAllProjects(projectRes)
        setFilteredProjects(
          projectRes.filter((project) => project.name.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        await initMeetings(projectRes)
      } else {
        cleanProjectState()
      }
    }
    setProjectsLoading(false)

    const docRef = doc(db, 'workspaces', user.active_workspace)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      setActiveWorkspace({ id: docSnap.id, ...docSnap.data() })
    }
  }

  async function initWorkspaces() {
    setWorkspacesLoading(true)

    const documents = {}
    const workspacesKeys = Object.keys(user.workspaces)
    for (let i = 0; i < workspacesKeys.length; i += 10) {
      const q = query(collection(db, 'workspaces'), where('__name__', 'in', workspacesKeys.slice(i, i + 10)))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          documents[doc.id] = doc.data()
        })
      } else {
        console.log('No document found with ids:', workspacesKeys.slice(i, i + 10))
      }
    }
    setWorkspaces(documents)
    setWorkspacesLoading(false)
  }

  async function refreshActiveWorkspace() {
    console.log('Refreshing active workspace', user.active_workspace)
    if (!user.active_workspace) return
    const docRef = doc(db, 'workspaces', user.active_workspace)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      setActiveWorkspace({ id: docSnap.id, ...docSnap.data() })
    }
  }

  async function initMeetings(projects) {
    const projectIdNameObject = projects.slice(0, 30).reduce((acc, proj) => {
      acc[proj.id] = proj.name
      return acc
    }, {})

    const projectIds = Object.keys(projectIdNameObject)
    let q
    let querySnapshot
    const newMeets = []
    for (let i = 0; i < projectIds.length; i += 10) {
      q = query(collection(db, 'meetings'), where('project_id', 'in', projectIds.slice(i, i + 10)))
      querySnapshot = await getDocs(q)
      if (querySnapshot.empty) continue
      querySnapshot.forEach((doc) => {
        newMeets.push({ ...doc.data(), id: doc.id })
      })
    }
    if (newMeets.length === 0) return cleanMeetingState()

    newMeets.sort((a, b) => {
      let timeA = a.created_at
      let timeB = b.created_at
      if (a.start) {
        timeA = new Date(a.start).getTime()
      }
      if (b.start) {
        timeB = new Date(b.start).getTime()
      }
      return timeA < timeB ? 1 : -1
    })
    setMeetings(newMeets)

    // get meetings overview
    const meetingsOverview = {}
    for (let i = 0; i < newMeets.length; i += 10) {
      q = query(
        collection(db, 'meetingsOverview'),
        where(
          '__name__',
          'in',
          newMeets.slice(i, i + 10).map((meet) => meet.id)
        )
      )
      querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          meetingsOverview[doc.id] = doc.data()
        })
      }
    }
    setMeetingsOverview(meetingsOverview)

    const meetingsWithFiles = newMeets.filter((meet) => meet.stored_name)
    const sessionsInfo = []
    for (const meet of meetingsWithFiles) {
      // set project name
      meet.project_name = projectIdNameObject[meet.project_id]
      // set thumbnail, If no thumbnail will use default image
      if (meet.thumbnail) meet.thumbnail = getPublicStorageUrl('thumbs', meet.thumbnail)
      // set background
      meet.background = meetingsOverview[meet.id]?.background
      sessionsInfo.push(meet)
    }
    setAllSessions(sessionsInfo)
    setSessions(sessionsInfo)
    setSessionsLoading(false)
  }

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    setProjects()
    initWorkspaces()
    getCalendarEvents()
  }, [user])

  useEffect(() => {
    if (!refreshContext) return
    setProjects()
    initWorkspaces()
    getCalendarEvents()
    setRefreshContext(false)
  }, [refreshContext])

  return (
    <DashboardContext.Provider
      value={{
        filteredProjects,
        setFilteredProjects,
        filterProjects,
        allProjects,
        setAllProjects,
        meetings,
        meetingsOverview,
        searchTerm,
        setSearchTerm,
        projectsLoading,
        workspacesLoading,
        workspaces,
        activeWorkspace,
        setActiveWorkspace,
        refreshActiveWorkspace,
        sessionsLoading,
        sessions,
        setSessions,
        allSessions,
        events,
        eventsLoading,
        shouldFireZoomToast,
        setProjects,
        setRefreshContext,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

DashboardContextProvider.propTypes = {
  children: PropTypes.node,
}
