import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { TimelineQuotes } from './quotes'
import { TimelineNeeds } from './needs'
import { TimelineQas } from './qas'
import { TimelineFeedbacks } from './feedbacks'
import { TimelineProblems } from './problems'

export function Timeline() {
  const { meeting, quotes, needs, feedbacks, problems, qas } = useContext(MeetingsContext)

  return (
    <div className="flex flex-col gap-[18px]">
      {!!quotes.length && <TimelineQuotes duration={meeting.duration} />}

      {!!needs.length && <TimelineNeeds duration={meeting.duration} />}

      {!!qas.length && <TimelineQas duration={meeting.duration} />}

      {!!feedbacks.length && <TimelineFeedbacks duration={meeting.duration} />}

      {!!problems.length && <TimelineProblems duration={meeting.duration} />}
    </div>
  )
}
